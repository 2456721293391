import { ref } from "vue";
import { defineStore } from "pinia";
import { useBapi } from "@/bapi-client";
import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import { ExternalInvoice, InvoicingPayee } from "@/bapi-client/types/external-invoicing";
import { usePaged } from "@/composables/paged";

export const useInvoicingStore = defineStore("invoicing", () => {
  const selectedPayee = ref<InvoicingPayee>();
  const selectedInvoice = ref<ExternalInvoice | null>(null);
  const { total, page, sort } = usePaged(1, 5, "ASC", "waybill_date");
  async function getPayee(customerId: string) {
    const response = await useBapi(BAPI_COMMANDS.EXTERNAL_INVOICING_GET_PAYEE, customerId);
    if (response.success) {
      selectedPayee.value = response.data;
    }
    return response;
  }

  async function getInvoice(customerId: string, invoiceId: string) {
    if (!selectedPayee.value?.id) return undefined;
    const result = await useBapi(
      BAPI_COMMANDS.EXTERNAL_INVOICING_GET_INVOICE,
      customerId,
      selectedPayee.value.id,
      invoiceId,
    );
    if (!result.success) return result;
    selectedInvoice.value = result.data;
    return result;
  }

  return { selectedPayee, selectedInvoice, total, page, sort, getPayee, getInvoice };
});
